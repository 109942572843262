/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.$ = window.jQuery = require("jquery");
require("../bootstrap");
require("slick-carousel");
require("./sidebar");
require("./book");
require("jquery-confirm");

$(window).on("scroll", function () {
    var scrollTop = $(this).scrollTop();
    if (scrollTop > 60) {
        $("body").addClass("scrolled");
    } else {
        $("body").removeClass("scrolled");
    }
});

$(function () {
    $("html, body").removeClass("is-loading");
    setTimeout(() => {
        $(".loading-box").fadeOut(300, function () {
            $(this).remove();
        });
    }, 100);
});

$(document).on("change", ".imgs-box input", function () {
    window.readURL(this);
});

window.readURL = function (input) {
    var img = $(input).closest(".img-item").find("img");
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            $(img).attr("src", e.target.result);
        };

        reader.readAsDataURL(input.files[0]);
    }
};
var i = 1;

window.addImage = function () {
    var html =
        '<div class="img-item img-' +
        i +
        '">' +
        '    <label style="cursor: pointer" >' +
        '        <input name="imgs[]" type="file" hidden>' +
        '        <img src="/img/img.svg" alt="">' +
        "    </label>" +
        "</div>";

    var item = $($(".imgs-box").prepend(html));
    item.find(".img-" + i + " input").click();
    i++;
};

window.msg = function (title, msg) {
    $.alert({
        title: title,
        content: msg,
        theme: "supervan",
        escapeKey: "ok",
        buttons: {
            ok: {
                keys: ["enter"],
            },
        },
    });
};

window.randomString = function (strLength = 5) {
    var result = [];
    var charSet =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    while (strLength--) {
        result.push(charSet.charAt(Math.floor(Math.random() * charSet.length)));
    }

    return result.join("");
};

window.convertFrom24To12Format = (time24) => {
    const [sHours, minutes] = time24.match(/([0-9]{1,2}):([0-9]{2})/).slice(1);
    const period = +sHours < 12 ? "AM" : "PM";
    const hours = +sHours % 12 || 12;

    return `${hours}:${minutes} ${period}`;
};
