let priceResponse = null;
let disMiles = null;
let stops = {};
const imgRoot = "/img/markers/";
let imgs = [
    imgRoot + "1.png",
    imgRoot + "2.png",
    imgRoot + "3.png",
    imgRoot + "4.png",
    imgRoot + "5.png",
    imgRoot + "6.png",
];
let startupLat = 0;
let startupLng = 0;
const dtw_position = {
    lat: "42.2162491",
    lng: "-83.3553642",
};

const dtw_names = ["dtw", "detroit metro airport", "detroit airport"];
const dtw_includes = [
    ["detroit", "airport"],
    ["detroit", "terminal"],
    ["dtw", "terminal"],
    ["dtw", "airport"],
];

$(function () {
    startupLat = $("#startup-lat").val();
    startupLng = $("#startup-lng").val();

    if ($(".addresses-box").length > 0) {
        var directionsDisplay;
        var directionsService = new google.maps.DirectionsService();

        let map = null;

        function initialize() {
            map = new google.maps.Map(document.getElementById("map_canvas"), {
                zoom: 13,
                center: new google.maps.LatLng(
                    parseFloat(startupLat),
                    parseFloat(startupLng)
                ),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                disableDefaultUI: true,
                panControl: false,
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                overviewMapControl: false,
                rotateControl: false,
                draggable: false,
                keyboardShortcuts: false,
            });

            directionsDisplay = new google.maps.DirectionsRenderer();
            directionsDisplay.setMap(map);
            directionsDisplay.setOptions({ suppressMarkers: true });

            addStop("Start-up");
            addStop("Pick-up", true);
            addStop();
        }

        function drawRoute() {
            $("#no-road-alert").hide();
            var request = {
                waypoints: [],
                origin: null,
                destination: null,
                travelMode: google.maps.TravelMode.DRIVING,
            };

            for (const key in stops) {
                if (Object.hasOwnProperty.call(stops, key)) {
                    const stop = stops[key];
                    if (stop.marker.getPosition().lat() == 0) continue;

                    if (request.origin == null) {
                        request.origin = new google.maps.LatLng(
                            stop.marker.getPosition().lat(),
                            stop.marker.getPosition().lng()
                        );

                        request.destination = new google.maps.LatLng(
                            stop.marker.getPosition().lat(),
                            stop.marker.getPosition().lng()
                        );
                    }

                    request.waypoints.push({
                        location: new google.maps.LatLng(
                            stop.marker.getPosition().lat(),
                            stop.marker.getPosition().lng()
                        ),
                    });
                }
            }

            if (request.waypoints.length <= 2) {
                setPrice("");
                return;
            }

            const pointA = request.waypoints[1];
            const pointLast = request.waypoints[request.waypoints.length - 1];
            let oneWayOnly = 
            (pointA.location.lat().toString() == dtw_position.lat &&
                pointA.location.lng().toString() == dtw_position.lng) ||
            (pointLast.location.lat().toString() == dtw_position.lat &&
                pointLast.location.lng().toString() == dtw_position.lng);

            if (!oneWayOnly || true) {
                const aibs = $(".addresses-box .ai-box:not(.startup-box)");
                const f_aibs_place = $(aibs[0]).find(".namei").val();
                const l_aibs_place = $(aibs[aibs.length - 1])
                    .find(".namei")
                    .val();

                dtw_names.forEach((dn) => {
                    if (
                        f_aibs_place.toString().toLowerCase().startsWith(dn) ||
                        l_aibs_place.toString().toLowerCase().startsWith(dn)
                    ) {
                        oneWayOnly = true;
                    }
                });

                if (!oneWayOnly) {
                    dtw_includes.forEach((di_array) => {
                        if (!oneWayOnly) {
                            if (
                                di_array.every((di) =>
                                    f_aibs_place
                                        .toString()
                                        .toLowerCase()
                                        .includes(di)
                                ) ||
                                di_array.every((di) =>
                                    l_aibs_place
                                        .toString()
                                        .toLowerCase()
                                        .includes(di)
                                )
                            ) {
                                oneWayOnly = true;
                            }
                        }
                    });
                }
            }

            directionsService.route(request, async function (response, status) {
                if (status == google.maps.DirectionsStatus.OK) {
                    let dis = 0;
                    if (
                        response &&
                        response.routes &&
                        response.routes.length > 0
                    ) {
                        let ir = 1;

                        response.routes[0].legs.forEach((leg) => {
                            if (
                                ir == response.routes[0].legs.length &&
                                oneWayOnly
                            ) {
                                console.log("NO BACK");
                            } else {
                                if (ir == 2 && oneWayOnly) {
                                    console.log("NO STARTUP");
                                } else {
                                    dis += leg.distance.value;
                                }

                                ir++;
                            }
                        });
                    }
                    disMiles = dis * 0.000621371192;

                    let res = await calculatePrice(
                        $("select[name=type]").val(),
                        disMiles,
                        $("input[name=hours]").val()
                    );
                    setPrice(res);

                    request.origin = request.waypoints[1];
                    request.destination =
                        request.waypoints[request.waypoints.length - 1];
                    request.waypoints.shift();

                    directionsService.route(
                        request,
                        async function (d_response, d_status) {
                            if (d_status == google.maps.DirectionsStatus.OK) {
                                directionsDisplay.setDirections(d_response);
                            }
                        }
                    );
                } else {
                    setPrice("");
                    $("#no-road-alert").show();
                }
            });
        }

        function addStop(title = "Stop", isStart = false) {
            if ($(".addresses-box .ai-box").length >= 7) {
                alert("You can't add more than 5 drops");
                return;
            }

            let position = new google.maps.LatLng(0, 0);

            if (title == "Start-up") {
                position = new google.maps.LatLng(
                    parseFloat(startupLat),
                    parseFloat(startupLng)
                );
            }

            let id = window.randomString(8);
            let html = `
                <div class="col-12 px-0 mt-4 ai-box ${
                    title == "Start-up" ? "startup-box" : ""
                } ${isStart ? "user-start-box" : ""}" id="${id}">
                    <div class="address-input">
                        <img src="" class="m-icon" />
                        <input class="input2" autocomplete="off"
                            placeholder="Start Typing Address or Airport Code...">
                        <input class="types" hidden value=""/>
                        <input class="lati" hidden value="${position.lat()}"/>
                        <input class="lngi" hidden value="${position.lng()}"/>
                        <input class="placei" hidden/>
                        <input class="namei" hidden/>
                        ${
                            !isStart
                                ? `<button class="del-btn" type="button"><i class="fas fa-times"></i></button>`
                                : ""
                        }
                    </div>
                </div>
            `;

            $(".addresses-box").append(html);

            let ele = $("#" + id);

            stops[id] = {
                marker: new google.maps.Marker({
                    position: position,
                    title: title,
                    map: map,
                }),
                input: ele,
            };

            const marker = stops[id].marker;

            marker.setVisible(false);

            if (title == "Start-up") {
                return;
            }

            var ele_auto = new google.maps.places.Autocomplete(
                document.getElementById(id).getElementsByTagName("input")[0],
                {}
            );

            ele.find("input.input2").on("focusout", function () {
                ele.find("input.input2").val("");
            });

            google.maps.event.addListener(
                ele_auto,
                "place_changed",
                function () {
                    var place = ele_auto.getPlace();
                    const center = new google.maps.LatLng(
                        place.geometry.location.lat(),
                        place.geometry.location.lng()
                    );
                    console.log(place);
                    ele.find(".types").val(JSON.stringify(place.types));
                    ele.find(".lati").val(place.geometry.location.lat());
                    ele.find(".lngi").val(place.geometry.location.lng());
                    ele.find(".placei").val(place.formatted_address);
                    ele.find(".namei").val(place.name);
                    ele.find("input.input2")
                        .val("")
                        .attr("placeholder", place.formatted_address)
                        .addClass("dark-placeholder");
                    marker.setPosition(center);
                    marker.setVisible(true);
                    map.panTo(center);
                    refreshUi();
                }
            );

            refreshUi();
        }

        function refreshUi() {
            setPrice(null);
            const addressInputsBoxes = $(".addresses-box .ai-box");
            for (let i = 0; i < addressInputsBoxes.length; i++) {
                const aiBox = $(addressInputsBoxes[i]);
                const ai = $(aiBox.find(".address-input")[0]);
                const id = aiBox.attr("id");
                ai.find("img").attr("src", imgs[i - 1]);
                ai.find(".types").attr("name", "waypoints[" + i + "][types]");
                ai.find(".lati").attr("name", "waypoints[" + i + "][lat]");
                ai.find(".lngi").attr("name", "waypoints[" + i + "][lng]");
                ai.find(".placei").attr("name", "waypoints[" + i + "][place]");
                stops[id].marker.setIcon({
                    url: imgs[i - 1],
                    scaledSize: new google.maps.Size(30, 30),
                });
            }
            drawRoute();
        }

        $("body").on("click", ".address-input .del-btn", function () {
            if ($(".addresses-box .ai-box").length <= 3) {
                return;
            }

            const aiBox = $(this).closest(".ai-box");
            const id = aiBox.attr("id");
            stops[id].marker.setMap(null);
            delete stops[id];
            aiBox.remove();
            refreshUi();
        });

        $("body").on("click", ".add-stop-btn", function () {
            addStop();
        });

        $("body").on(
            "change",
            "select[name=type], select[name=number_of_passengers]",
            function () {
                refreshUi();
            }
        );

        $("body").on("keyup", "input[name=hours]", function () {
            refreshUi();
        });

        initialize();
        // google.maps.event.addDomListener(window, "load", initialize);

        $("body").on("submit", "#book-form", function (e) {
            let stopsCount = 0;
            let mustDelete = [];
            for (const key in stops) {
                if (Object.hasOwnProperty.call(stops, key)) {
                    const stop = stops[key];
                    if (stop.marker && stop.marker.position.lat() != 0) {
                        stopsCount++;
                    } else {
                        mustDelete.push(stop);
                    }
                }
            }
            if (stopsCount < 1) {
                window.msg("Sorry..", "Please add a pickup address");
                e.preventDefault();
                return;
            }
            if (stopsCount < 2) {
                window.msg("Sorry..", "Please add at least one stop address");
                e.preventDefault();
                return;
            }

            mustDelete.forEach((md) => {
                $(md.input).remove();
            });
        });
    }
});

function setPrice(obj) {
    priceResponse = null;
    $("#next-2").prop("disabled", true);
    if (obj == null) {
        $("#distance").html("<img src='/img/loading2.svg' height='23'/>");
    } else if (obj == "") {
        $("#distance").html("");
    } else {
        priceResponse = obj;
        let showPrice = $("[name=number_of_passengers]").val() != "More";
        $("#distance").html(
            // ${obj.miles.toFixed(1)} Miles
            //  ${
            //      $("select[name=type]").val() == "Hourly Ride"
            //          ? '<i class="c2">•</i> ' +
            //            $("input[name=hours]").val() +
            //            " Hours "
            //          : ""
            //  }
            `${showPrice ? "<i class='c2'>•</i> $" + obj.total.toFixed(2) : ""}`
        );
        $("input[name=miles]").val(disMiles);
        if (showPrice) {
            $("#more-alert").hide();
        } else {
            $("#more-alert").show();
        }
        $("#next-2").prop("disabled", false);
    }
}

window.refreshInvoiceAndOpen = () => {
    let type = $("select[name=type]").val();
    let invoice = $("#invoice-modal");
    invoice
        .find(".name")
        .html(
            $("input[name=fname]").val() + " " + $("input[name=lname]").val()
        );

    invoice
        .find(".pickup-date")
        .html(
            new Date($("input[name=drop_date]").val())
                .toLocaleDateString("en-us")
                .replace("/", "-")
                .replace("/", "-") +
                "&nbsp;&nbsp;" +
                window.convertFrom24To12Format($("input[name=drop_time]").val())
        );

    invoice.find(".phone").html($("input[name=phone]").val());

    invoice.find(".email").html($("input[name=email]").val());

    invoice.find(".fuel-fee").html("$" + priceResponse.fuelCharge);
    invoice.find(".stc-fee").html("$" + priceResponse.stcCharge);
    invoice.find(".gratuity-fee").html("$" + priceResponse.gratuityCharge);
    invoice.find(".airport-fee").html("$" + priceResponse.airportAccessFee);

    invoice.find(".stops-tbody").html(`
        <tr class="heading">
            <td>Stops</td>
            <td></td>
        </tr>
    `);

    // invoice
    //     .find(".total-miles")
    //     .html(`${disMiles ? disMiles.toFixed(1) : ""} Miles`);

    invoice.find(".total-hours").html(`${$("[name=hours]").val()}`);
    invoice
        .find(".subtotal")
        .html(`${priceResponse ? priceResponse.subTotal.toFixed(2) : ""}`);

    invoice
        .find(".total-price")
        .html(`$${priceResponse ? priceResponse.total.toFixed(2) : ""}`);

    invoice.find(".hours-tr, .airport-tr").hide();

    if (type == "Hourly Ride") {
        invoice.find(".hours-tr").show();
    }

    if (
        type == "Airport pick-up/drop-off" &&
        JSON.parse($(".user-start-box .types").val()).includes("airport")
    ) {
        invoice.find(".airport-tr").show();
    }

    let i = 0;
    for (const key in stops) {
        if (Object.hasOwnProperty.call(stops, key)) {
            const stop = stops[key];
            if (i == 0) {
                //startup point
            } else if (i == 1) {
                invoice.find(".pickup-address").html(
                    $("#" + key)
                        .find(".placei")
                        .val()
                );

                invoice
                    .find(".pickup-icon")
                    .html(`<img src="${imgs[i - 1]}" height="23" />`);
            } else {
                const place = $("#" + key)
                    .find(".placei")
                    .val();
                invoice.find(".stops-tbody").append(
                    `<tr class="item">
                        <td>${place}</td>
                        <td><img src="${imgs[i - 1]}" height="23" /></td>
                    </tr>`
                );
            }
            i++;
        }
    }
    $("#invoice-modal").modal("show");
};

let priceAjax = null;
window.calculatePrice = async (type, miles, hours) => {
    if (priceAjax) {
        priceAjax.abort();
        priceAjax = null;
    }

    const waypoints = [];

    const st_inp = $(".addresses-box .ai-box");
    for (let i = 0; i < st_inp.length; i++) {
        const itm = $(st_inp[i]);
        waypoints.push({
            lat: itm.find(".lati").val(),
            lng: itm.find(".lngi").val(),
            types: itm.find(".types").val(),
        });
    }

    return new Promise((resolve, reject) => {
        priceAjax = $.ajax({
            headers: {
                "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
            },
            type: "POST",
            url: `/calculate-price`,
            data: {
                miles: miles,
                stops: waypoints,
                type: type,
                hours: hours,
            },
            success: (data) => {
                resolve(data);
            },
        });
    });
};
